import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Label, Button, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { getfund, editconf } from '../../../Redux/Actions/Payment';
import Loader from '../../../components/common/Loader';
import { setAlert } from '../../../Redux/Actions/alert/alert';
const FundDetail = ({ getfund, fund, editconf, setAlert }) => {
  const [ar_amount, setam] = useState(0);
  const [ar_trigger, sedesc] = useState(0);
  const [ar_status, setstat] = useState(false);
  useEffect(() => {
    getfund();
  }, [getfund]);
  useEffect(() => {
    if (fund) {
      console.log('Fund', fund, fund.ar_amount);
      setam(fund.ar_amount || 0); // Set ar_amount to fund.ar_amount or 0 if undefined
      sedesc(fund.ar_trigger || 0); // Set ar_trigger to fund.ar_trigger or 0 if undefined
      setstat(fund.ar_status || false); // Set ar_status to fund.ar_status or false if undefined
    }
  }, [fund]);

  const submit = () => {
    editconf({ ar_amount, ar_trigger, ar_status });
    // toggle();
  };
  if (fund === null) {
    return <Loader />;
  }
  return (
    <Row className="justify-content-center">
      <Col xs="12">
        <Row className="pt-2">
          <Col xs="12">
            <Input
              bsSize="sm"
              style={{ fontSize: '0.95em' }}
              type="number"
              placeholder="Amount To Add "
              onChange={e => {
                setam(e.target.value);
              }}
              value={ar_amount}
            />
            <small id="emailHelp" class="form-text text-muted">
              Enter the Auto Recharge Amount
            </small>
          </Col>
          <Col xs="12" className="pt-2">
            <Input
              bsSize="sm"
              style={{ fontSize: '0.95em' }}
              type="number"
              placeholder="Trigger Amount"
              onChange={e => {
                sedesc(e.target.value);
              }}
              value={ar_trigger}
            />
            {ar_trigger != 0 ? (
              <small id="emailHelp" class="form-text text-muted">
                Auto Recharge will trigger when the amount is less than {ar_trigger}
              </small>
            ) : null}
          </Col>
        </Row>
        <Row className="pt-2">
          <Col xs="12">
            <Label>Auto Trigger</Label>
            <Input
              bsSize="sm"
              style={{ fontSize: '0.95em' }}
              type="select"
              placeholder="Auto Recharge"
              onChange={e => {
                setstat(e.target.value === 'true');
              }}
              value={ar_status ? 'true' : 'false'}
            >
              <option value="true">Enabled</option>
              <option value="false">Disabled</option>
            </Input>
          </Col>
          <Col className="pt-4" xs="12">
            <Button color="primary" size="sm" onClick={submit}>
              Update Configuration
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
const mapeStateToProps = state => ({
  fund: state.payment.fund
});
export default connect(
  mapeStateToProps,
  { getfund, editconf, setAlert }
)(FundDetail);
