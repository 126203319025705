import { Device } from '@twilio/voice-sdk';

let device;

export function initializeDevice(token) {
  if (!device) {
    device = new Device(token,{
    allowIncomingWhileBusy: true 
  });
  }
  return device;
}

export function destroyDevice() {
  console.log('Destroy called');
  if (device) {
    device.destroy();
    console.log('destroyed');
    device = null;
  }
}
